<template>
  <div class="container">
    <div class="success-info">
      <i class="success-icon"></i>
      <div class="bg-text">提交成功!</div>
    </div>
    <div class="title">提交成功</div>
    <i class="success-icon pc-s-icon"></i>
    <div class="success-bg">
      <div class="text-info" style="font-size:16px;">我们会尽快对个人简历内容进行确认，并通过本次预留的手机号<strong>{{ phone }}</strong>与您取得联系。</div>
      <div class="text-info" style="font-size:16px;">请您留意后续的电话通知，感谢您的配合!</div>
    </div>
    <div class="divide"></div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      countDown: 10,
      timer: null
    }
  },
  computed: {
    phone(){
      return sessionStorage.getItem('phone')
    }
  },
  methods: {
    startCountDown(){
      this.timer = setInterval(() => {
        this.countDown --
        if(this.countDown <= 0){
          clearInterval(this.timer)
          window.location.href = 'https://www.chinargb.com.cn/'
        }
      }, 1000);
    },
    clearTimer(){
      if(this.timer){
        clearInterval(this.timer)
      }
      this.timer = null
      this.countDown = 90
    },
  },
  mounted() {
    this.startCountDown()
  },
  beforeDestroy() {
    this.clearTimer()
  },
}
</script>
<style scoped>

</style>